@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@font-face {
    font-family: 'GoodDog Plain';
    src: url('fonts/GoodDogPlain.woff2') format('woff2'), 
    url('fonts/GoodDogPlain.woff') format('woff'), 
    url('fonts/GoodDogPlain.woff') format('woff'), 
    url('fonts/GoodDogPlain.ttf') format('truetype'), 
    url('fonts/GoodDogPlain.svg#GoodDogPlain') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root{
    --theme: #dc3545;
    --themedark: #9c0110;
}
.logo-caption img{
    height: 50px;
    object-fit: contain;
}
html ,body{
  scroll-behavior: auto!important;
}
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #171725;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
}
.icon{
    stroke: unset;
    stroke-width: 0px;
    fill: currentColor;
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
}
.icon-1x{transform: scale(1.1)} .icon-2x{transform: scale(1.15)}
.icon-3x{transform: scale(1.2)} .icon-4x{transform: scale(1.25)}
.icon-5x{transform: scale(1.3)} .icon-6x{transform: scale(1.35)}
.icon-7x{transform: scale(1.4)} .icon-8x{transform: scale(1.45)}
.l{box-shadow: inset 0 0 0 1px red;}

header{
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    margin-bottom: 20px;
    position: sticky;
    top:0;
    z-index: 50;
    background-color: #000;
}

header .search-bar {
    width: 430px;
}
.menu-option{
    flex-grow: 0;
}

.search-bar svg.icon {
    color: #ffffff;
    font-weight: bold;
}

/* Start search baar mobile view  */
.search-box {
    width: 300px;
    height: 70px;
    position: relative;
  }
  
  .search-wrapper .input {
    position:absolute;
    top: 10px;
    right:0px;
    box-sizing: border-box;
    width: 0px;
    height: 45px;
    padding: 0 20px;
    outline: none;
    font-size: 18px;
    border-radius: 50px;
    color: #b9b9b9;
    border:0px;
    background: transparent;
    transition: all 0.8s ease;
  }
  
  .search-wrapper ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #eeeeee;
  }
  .search-wrapper ::-moz-placeholder {
    /* Firefox 19+ */
    color: #eeeeee;
  }
  .search-wrapper :-ms-input-placeholder {
    /* IE 10+ */
    color: #eeeeee;
  }
  .search-wrapper :-moz-placeholder {
    /* Firefox 18- */
    color: #eeeeee;
  }
  
  .search-wrapper  .btn {
    position: absolute;
    background: transparent;
    border-radius: 50%;
    right: -18px;
    top: 16px;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: #fff;
    transition: all 0.8s ease;
  }
  
  .search-wrapper  .input.active {
    width: 264px;
    right: -18px;
    border: 1px solid #fff;
  }
  
  .search-wrapper  .btn.animate {
    transform: rotate(-360deg);
    right: 100px;
  }
  .hide-icon{
      opacity: 0;
      visibility: hidden;
      display: none;
  }
  .show-icon{
      opacity: 1;
      visibility: visible;
      display: block !important;
  }
  #cross-btn-icon{
      display: none;
  }
  .caption-sort button {
      color: #000;
      border: none;
      background-color: #eee;
  }
  .caption-sort button.active {
      color: #dc3545;
      border: none;
      pointer-events: none;
      background-color: #eee;
  }


/* Start | Card Design */
.card-box{
    transition: 400ms;
}
.card-box:hover{
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.175)!important;
}
.card-imgbox{
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.card-imgbox img{
    width: 100%;
    transform: scale(1);
    transition: 5s cubic-bezier(0.1, .52, .005, 1);
}
.card-box:hover .card-imgbox img{
    transform: scale(1.2);
}
.card-linkshape{
    position: absolute;
    top: 12px;
    right: 12px;
    color: #fff;
    border: none;
    padding: 6px;
    background: rgba(0,0,0,0.75);
    border-radius: 4px;
    transition: 400ms;
    transform: scale(0);
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
    z-index: 1;
}
.card-linkshape .icon{
    width: 28px;
    height: 28px;
    vertical-align: middle;
}
.card-box:hover .card-linkshape{
    transform: scale(1);  
}
.card-linkshape:hover{
    color: var(--theme);
    background: rgba(255,255,255,0.9);
}
.card-linkshape.liked{
    transform: scale(1);  
    color: var(--theme);
    background: rgba(255,255,255,0.9);
}
.card-bubbleaction{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f4f4f4;
}
.card-bubbleaction li{
    margin-right: 18px;
}
.card-bubbleaction li span{
    margin-left: 5px;
    display: inline-flex;
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.75;
}
.card-bubbleaction li .icon{
    font-size: 15px;
    color: var(--theme);
    opacity: 0.75;
}
.card-bubbleaction li:nth-child(1) .icon{
    font-size: 18px;
    transform: translateY(1px);
}
/* End | Card Design */

/*custom upload button css*/
.drop-input {
    display: flex;
    position: relative;
    width: 100%;
    height: 70px;
    border-radius: 6px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    transition: 350ms;
    cursor: pointer;
	border: 1px dashed;
}
.drop-input input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.upload-box{
    margin-top: 70px;
}
/*end*/


/* Start | Footer */
body{
    position: relative;
    min-height: 100vh;
    padding-bottom: 100px;
    background-color: #eee;
}
footer{
    background-color: #fff;
    color: #222;
    font-size: 14px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
footer a{
    line-height: 2;
    color: #222;
    text-decoration: none;
    transition: 350ms;
}
footer a:hover{
    color: var(--theme);
}
@media(max-width: 767px){
    body{
        padding-bottom: 0;
    }
    footer{
        margin-top: 20px;
        position: relative;
    }
}
/* End | Footer */


/* =========================== */
/* Start | Admin Layout Style  */
/* =========================== */
:root{
    --dashboard-color: #044a63;
}


/* Start | Login Style */
.login-section{
    top: 0;
    left: 0;
    position: absolute;
    background: #343a40;
    width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-height: 100vh;
  padding: 15px;
}
.logo-form{
    display: block;
    max-width: 420px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 12px;
    padding: 34px 30px;
    user-select: none;
}
.login-logo{
    text-align: center;
}
.login-logo img{
    width: 120px;
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}
.login-heading{
    font-size: 24px;
    line-height: 36px;
    color: #171725;
    font-weight: 600;
}
/* End | Login Style */

.main-wrapper{
    padding-left: 252px;
    padding-top: 60px;
    transition: 300ms;
}

/* Start | Top Bar Design*/
.top-bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    z-index: 9;
    background-color:rgba(255,255,255,0.98);
    padding-left: 250px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.1);
    transition: 300ms;
}
.top-bar .row{
    height: 44px;
}
.top-bar .dropdown-menu{
    margin-top: 11px!important;
}
.menu-collapse{
    width: 44px;
    height: 44px;
    font-size: 22px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    color: #044a63;
    margin-left: 2px;
}
.btn-username{
    color: #666;
    display: inline-flex;
    align-items: center;
    box-shadow: none;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 14px;
    user-select: none;
}
.btn-username.show, .btn-username.show:hover{
    color: #044a63;
}
.btn-username:hover{
    color: #343a40;
    outline: none;
}
.btn-username:focus{
    box-shadow: none;
    outline: none;
}
.btn-username .icon{
    font-size: 22px;
    margin-right: 7px;
}
.btn-username span{
    white-space: nowrap;
    margin-right: 5px;
}
.page-heading{
    position: fixed;
    left: 309px;
    top: 8px;
    z-index: 10;
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    padding-left: 20px;
    border-left: 2px solid #eee;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--dashboard-color);
    transition: 300ms;
}
.page-heading span{
    font-weight: normal;
    margin-left: 10px;
    line-height: 1;
    font-size: 14px;
}
/* End | Top Bar Design*/


/* Start | Aside Bar Design*/
.aside-bar{
    position: fixed;
    width: 250px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 14px;
    transition: 300ms;
    background-color: var(--dashboard-color);
}
.aside-logo{
    width: 100%;
    height: 60px;
    margin-bottom: auto;
    user-select: none;
}
.aside-logo img{
    height: 60px;
}
.aside-menu{
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: calc(100% - 60px - 20px);
    margin-top: 20px;
    overflow: hidden;
    overflow-y: auto;
}
.aside-menu a{
    display: flex;
    align-items: center;
    padding: 14px;
    text-decoration: none;
    font-size: 14.5px;
    letter-spacing: 0.5px;
    line-height: 1.4;
    background-color: rgba(0,0,0,0);
    border-radius: 4px;
    margin-bottom: 4px;
    color: #eee;
    transition: 300ms;
}
.aside-menu a .icon{
    margin-right: 14px;
    width: 22px;
    min-width: 22px;
    height: 22px;
    color: #fff;
}
.aside-menu a.active, .aside-menu a:hover{
    background-color:#1d6a86;
    color: #fff;
}
/* End | Aside Bar Design*/

/* Start| Loader */
.loader{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: rgba(255,255,255,0.95); */
    z-index: 100;
    transition: 300ms;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader::after{
    content: '';
    width: 60px;
    height: 60px;
    border: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-radius: 50%;
    animation: loadermoving 1s linear infinite;
}
@keyframes loadermoving{
    from{transform: rotate(0);}
    to{transform: rotate(360deg);}
}
.loader.loader-hide{
    opacity: 0;
    visibility: hidden;
}
/* End | Loader */


.menu-toggle .main-wrapper{
    padding-left: 0;
}
.menu-toggle .top-bar{
    padding-left: 0;
}
.menu-toggle .aside-bar{
    left: -270px;
}
.menu-toggle .page-heading{
    left: 56px;
}
.add-listing .form-control {
    background-color: rgb(255 255 255 / 27%);
    background-clip: padding-box;
    border: 1px solid rgb(80 80 80 / 51%);
    min-height: 55px;
}

.add-listing .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
}

.userlist table.border.table.table-striped.table-hover th, .userlist table.border.table.table-striped.table-hover td {
    border-right: 1px solid #d8d8d8;
    text-align: center;
}


@media (max-width: 1040px){
    .main-wrapper{
        padding-top: 64px;
        padding-left: 200px;
    }
    .top-bar{
        padding-left: 200px;
        background-color: rgba(255,255,255,0.6);
        backdrop-filter: blur(4px);
    }
    .aside-bar{
        width: 200px;
    }
    .page-heading{
        position: relative;
        left: 0px;
        top: auto;
        font-size: 26px;
        height: auto;
        z-index: 7;
        line-height: 32px;
        padding-left: 12px;
        border-left: 4px solid #ccc;
    }
    .menu-toggle .page-heading {
        left: auto;
    }
}
@media (max-width: 720px){
    .main-wrapper, .top-bar {
        padding-left: 0;
    }
    .aside-bar{
        width: 250px;
        left: -270px;
        box-shadow: 2px 0px 10px #999;
    }
    .menu-toggle .menu-collapse{
        margin-left: 250px;
        z-index: 9;
    }
    .menu-toggle .aside-bar{
        left: 0px;
    }
    .menu-toggle .page-heading{
        left: auto
    }
    .menu-toggle .container-common{
        opacity: 0.1;
        pointer-events: none;
    }
    .menu-toggle .col-dropdown{
        display: none;
    }

}
/* =========================== */
/* End | Admin Layout Style  */
/* =========================== */





/* Start | Common Style */
.w-40{width: 40%;} .w-45{width: 45%;}
.w-46{width: 46%;} .w-47{width: 47%;}
.w-48{width: 48%;} .w-49{width: 49%;}
.btn-w60{min-width: 60px;}
.btn-w80{min-width: 80px;}
.btn-w100{min-width: 100px;}
.btn-w120{min-width: 120px;}
.btn-w140{min-width: 140px;}
.btn-w150{min-width: 150px;}
.btn-w160{min-width: 160px;}
.btn-w172{min-width: 172px;}
.btn-w180{min-width: 180px;}
.h-160{height: 160px;}
.h-180{height: 180px;}
.h-200{height: 200px;}
.h-220{height: 220px;}
.text-theme{color: var(--theme)}

.fs-12px{font-size: 12px;}
.fs-13px{font-size: 13px;}
.fs-14px{font-size: 14px;}
.fs-15px{font-size: 15px;}
.fs-16px{font-size: 16px;}
.minwh-30{
    min-width: 30px;
    min-height: 30px;
}

.fw-medium{font-weight: 500;}
.mt-13px{margin-top: 13px} .mt-14px{margin-top: 14px}
.mt-15px{margin-top: 15px} .mt-16px{margin-top: 16px}
.mt-17px{margin-top: 17px} .mt-18px{margin-top: 18px}
.p-2px{padding: 2px;}

.rounded-4{border-radius: .35rem!important}
.rounded-5{border-radius: .4rem!important}
.rounded-6{border-radius: .45rem!important}
.rounded-7{border-radius: .5rem!important}
.rounded-8{border-radius: .55rem!important}
.rounded-9{border-radius: .6rem!important}
.rounded-10{border-radius: .65rem!important}
.show-lg{display: none;}
.cursor-pointer{cursor:pointer;}
/* End | Common Style */

/* End | Media Query */

html,body, div, section{scrollbar-color:#bababa transparent;scrollbar-width:thin;}
::-webkit-scrollbar{width:0px;height:0px;background:#000;border-radius:0px;}
::-webkit-scrollbar-track{border-radius:0px}
::-webkit-scrollbar-thumb{border-radius:0px;background-color:#777;}
::-webkit-scrollbar-thumb:hover{background-color: var(--theme)}





.pagination .page-link{
    color: var(--theme);
}
.page-item.active .page-link {
    background-color: var(--theme);
    border-color: var(--theme);
}
.sub-menu-list .card {
    background: rgb(29 106 134);
    padding: 0.5rem;
    border-radius: 0px;
}
.sub-menu-list .card ul {
    padding-left: 5px;
}
.sub-menu-list .card ul li {
    list-style: none;
}
.aside-menu li{
    position: relative;
}
.aside-menu [aria-expanded="false"]:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    right: 26px;
    transition: 500ms;
    transform: rotate(45deg);
    border-radius: 1px;
}

.aside-menu [aria-expanded="true"]:before,
.collapsed-more.active:not(.collapsed):before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    right: 26px;
    transition: 500ms;
    transform: rotate(135deg);
    border-radius: 1px;
}

/* Start | Preview Share */
.share-listing{
    position: fixed;
    width: 48px;
    top: 20%;
    left: 0;
}
.share-listing button{
    width: 48px;
    min-height: 48px;
    font-size: 22px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0;
    margin: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    background-color: rgba(255,255,255,1);
    transition: 500ms cubic-bezier(1, 2, 0.1, 1);
}
.share-listing button:hover, .share-listing button.active{
    background-color: var(--theme);
    color: #fff;
    width: 64px;
}
.share-listing button.active{
    width: 48px;
}
.share-listing span{
    display: block;
    width: 100%;
    padding-top: 5px;
    font-size: 12px;
    line-height: 12px;
}



.container-preview{
    margin-top: -20px;
    padding-right: 312px;
}
.thumb-listing{
    position: fixed;
    right: 0;
    top: 54px;
    height: calc(100vh - 54px);
    width: 300px;
    background-color: #171725;
    overflow: hidden;
    overflow-y: scroll;
    padding: 15px 15px 100px 15px;
    border-right: 1px dashed rgba(255,255,255,0.25);
}
.cart-thumbbox{
    display: block;
    overflow: hidden;
    text-decoration: none;
    backface-visibility: hidden;
}
.cart-thumbbox img{
    width: 100%;
    display: block;
    transition: 500ms cubic-bezier(0.5, 1, 0.5, 1);
}
.cart-thumbbox:hover img{
    transform: scale(1.1);
}
body.modal-open{
    padding-right: 0px !important;
    overflow: auto;
    overscroll-behavior-y: contain;
}
.comment-listing{
    list-style-type: none;
    padding: 1px 15px;
    border-radius: 0.25rem;
    background-color: #fff;
}
.comment-listing li{
    border-left: 3px solid #fddde0;
    border-radius: 6px;
    padding-left: 12px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.comment-listing li p{
    font-size: 14px;
    margin: 0 0 6px 0;
}
.comment-listing li span{
    display: inline-flex;
    font-size: 12.5px;
    letter-spacing: 12.5;
    margin: 05px 0 0 0;
    font-weight: 900;
    opacity: 0.8;
    letter-spacing: 0.5px;
}
.comment-listing li button{
    background-color: #eee;
    border-radius: 20px;
    padding: 1px 12px;
    border: none;
    font-size: 12px;
    margin-left: 12px;
    color: #fa5f6c;
    font-weight: 400;
    letter-spacing: 0.5px;
    transition: 350ms;
}
.comment-listing li button:hover{
    background-color: #fa5f6c;
    color: #fff;
}
/* End | Preview Share */



/* /////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////// */
/* Start | Bubble Image Edit Style */
.caption-editor{
    width: 100%;
    position: relative;
    margin-top: -20px;
}
.selected-picture{
    width: 100%;
    /* max-width: 100%; */
    display: block;
    margin: 0 auto;
    z-index: 0;
    pointer-events: none;
    user-select: none;
}
.caption-watermark{
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 200px;
    opacity: 0.5;
    z-index: 10;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
.caption-watermark img{
    width: 100%;
    display: block;
}

.preview-tools{
    position: fixed;
    top: 70px;
    right: 14px;
    z-index: 10;
    white-space: nowrap;
}
.image-preview{
    pointer-events: none;
    user-select: none;
}
.image-preview ~ p{
    font-size: 13.5px;
}
.nav-bubbles{
    outline: none !important;
}
.element-tools{
    background: rgba(0,0,0,0.65);
    z-index: 10;
    min-width: 85px;
}
.element-tools .element {
    border-bottom: 1px solid rgba(255,255,255,0.15);
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 82%;
    background-repeat: no-repeat!important;
    background-position: center!important;
    cursor: move;
    max-width: 100%;
    margin: auto;
}
.element-tools .element-imgfit{
    width: 50%;
    height: auto;
}
.element-tools .element:last-child{
    border-bottom: none;
}
.caption-editor .element{
    min-width: 150px;
    min-height: 107px;
    width: 400px;
    height: 286px;
    z-index: 10;
    background-repeat: no-repeat!important;
    background-position: 0 0!important;
    background-size: 100% 100%!important;
    /* cursor: move; */
}
.caption-editor .element-imgfit{
    min-width: inherit;
    min-height: inherit;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
}
.ui-resizable-handle, html.touch .ui-resizable-handle {
    display: block;
    width: 34px;
    height: 34px;
    right: 0;
    bottom: 10px;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0,0,0,0.5);
    cursor: se-resize;
    background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z'/></svg>");
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center center;
    transition: 50ms;
    transform: scale(0) rotate(90deg);
    visibility: hidden;
}
.caption-editor .element:hover .ui-resizable-handle,
.caption-editor .element:focus-within .ui-resizable-handle,
.caption-editor .element:focus .ui-resizable-handle,
.caption-editor .element.active .ui-resizable-handle{
    transform: scale(1) rotate(90deg);
    visibility: visible;
}

.edit-tools{
    width: 34px;
    height: 34px;
    position: absolute;
    border-radius: 4px;
    cursor: pointer;
    top: 5px;
    right: 0;
    z-index: 12;
    box-shadow: 0 0 6px rgba(0,0,0,0.5);
    background-repeat: no-repeat!important;
    background-position: center center!important;
    transition: 50ms;
    transform: scale(0);
    visibility: hidden;
}
.caption-editor .element:hover .edit-tools,
.caption-editor .element:focus-within .edit-tools,
.caption-editor .element:focus .edit-tools,
.caption-editor .element.active .edit-tools{
    transform: scale(1);
    visibility: visible;
}


[data-deletebubble]{
    background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='0 0 16 16'><path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/></svg>");
    background-size: 80% 80%;
}
[data-rotatebubble]{
    top: 45px;
    background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z'/></svg>");
    background-size: 65% 65%;
}
[data-changefontbubble]{
    top: 85px;
    background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='0 0 16 16'><path d='m2.244 13.081.943-2.803H6.66l.944 2.803H8.86L5.54 3.75H4.322L1 13.081h1.244zm2.7-7.923L6.34 9.314H3.51l1.4-4.156h.034zm9.146 7.027h.035v.896h1.128V8.125c0-1.51-1.114-2.345-2.646-2.345-1.736 0-2.59.916-2.666 2.174h1.108c.068-.718.595-1.19 1.517-1.19.971 0 1.518.52 1.518 1.464v.731H12.19c-1.647.007-2.522.8-2.522 2.058 0 1.319.957 2.18 2.345 2.18 1.06 0 1.716-.43 2.078-1.011zm-1.763.035c-.752 0-1.456-.397-1.456-1.244 0-.65.424-1.115 1.408-1.115h1.805v.834c0 .896-.752 1.525-1.757 1.525z'/></svg>");
    background-size: 65% 65%;
}
.jqui-dragger{
    top: auto;
    bottom: 10px;
    right: 46px;
    cursor: move;
    background: #fff url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%23dc3545" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/></svg>');
    background-size: 65% 65%;
}
.jqui-dragger-frame{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: move;
}
.textarea-box{
    width: 46%;
    min-height: 60px;
    height: 46%;
    padding: 0;
    top: 27%;
    left: 27%;
    position: absolute;
    text-align: center;
    cursor: text;
    overflow: visible;
    resize: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.textarea-input{
    width: 100%;
    height: 100%;
    font-size: 50px;
    outline: none;
    font-family: 'GoodDog Plain';
    word-wrap: normal;
    line-height: 1;
}
.textarea-input:focus {
    outline: none;
}
.textarea-input img{
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
}

span.text-replacement {
    text-align: center;
    margin:0px;
    padding:0px;
    display:block;
    padding:0px;
    font-size:40px;
    line-height: 1;
    font-family:'Roboto', Arial,Helvetica;
    background:#ff0;
    opacity:1;
    top: 0px;
    left: 200px;
    position: absolute;
    border: 1px solid blue;
}
span.text-replacement-word {
    display:block;
    padding:0px;
    margin:0px;
    opacity:1;
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid orange;
}

[data-setfontfamily="fantasy"]{font-family: fantasy}
[data-setfontfamily="monospace"]{font-family: monospace}
[data-setfontfamily="Arial"]{font-family: Arial}
[data-setfontfamily="GoodDog Plain"]{font-family: 'GoodDog Plain'}

.element-medium .textarea-box{
    width: 68%;
    left: 16%;
    height: 55%;
    top: 22%;
}
.element-square .textarea-box{
    width: 76%;
    left: 12.5%;
    height: 61%;
    top: 17.5%;
}
.element-angry .textarea-box{
    width: 58%;
    left: 21%;
    height: 48%;
    top: 24%;
}
.element-thinking .textarea-box{
    width: 68%;
    left: 18%;
    height: 44%;
    top: 26.5%;
}

#generatereport{
    position: fixed; 
    top: 10px; 
    right: 10px;
}

.bubbles-errorbox{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
z-index: 12;
background: rgba(0,0,0,0.70);
backdrop-filter: blur(2px);
visibility: hidden;
opacity: 0;
transition: 150ms;
padding: 15px;
}
.body-bubbleerror .bubbles-errorbox{
visibility: visible;
opacity: 1;
}
.bubbles-errorbox h2{
max-width: 920px;
color: #fff;
text-align: center;
font-weight: 400;
line-height: 1.5;
padding-top: 50px;
}
/* End | Bubble Image Edit Style */
/* /////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////// */
.theme-btn {
    background-color: #dc3545!important;
    border-color: #dc3545!important;
}
.theme-btn:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

/* Share Button Style */

.react-share__ShareButton{
    background-color: rgba(255,255,255,1) !important;
    font-size: 22px !important;
}

.react-share__ShareButton:hover{
    background-color: var(--theme) !important;
    color: #fff !important;
}

/* Captcha Style */
.captcha-control{
    display: flex;
}

.captcha-control div{
    display: flex;
    margin-right: 10px;
}

a#reload_href{
    cursor: pointer;
    color: #dc3545 !important;
    font-size: 20px;
    margin-top: 5px;
    margin-left: 10px;
}

/* Start | Ads Codes */
.ads-code{
    display: block;
    overflow: hidden;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
} 
.ads-code .ads-title{
    height: 20px;
    color: #fff;
    background-color: #dc3545;
    width: 100%;
}
.ads-code .ads-content{
    height: auto;
    color: #fff;
    background-color: #414141;
    width: 100%;
    object-fit: cover;
}
.ads-code .ads-content a{
    display: block;
    position: relative;
}
.ads-code .ads-content img{
    max-width: 100%;
}
.s300x300{
    max-width: 300px;
    max-height: 320px;
}
.s300x600{
    max-width: 300px;
    max-height: 620px;
}
/* End | Ads */



.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin-top: 2px;
    margin-left: -60px;
}
.previews-btn button{
    display: none;
}

.dnone{
    display: none;
}

.dblock{
    display: block;
}

.mtp-10{
    margin-top: 10%;
}

/* Responsive media Style */
@media (max-width:450px){
    .search-box{
        width: 204px;
    }
    .search-wrapper .input.active{
        width: 210px;
    }
}
@media (max-width:991px){
    header .border-start {
        border-left: none !important;
    }
    .navbar-nav{
        margin-right: 12px;
        margin-bottom: 12px;
        border-top: 1px solid #444;
    }
    .caption-tool-menu{
        width:100%;
    }
    .caption-tool-menu .navbar-nav{
        margin-top: 5px;
        flex-direction: row;
        padding-top: 11px;
    }
    .caption-tool-menu .navbar-nav .dropdown-menu {
        position: absolute;
        margin-left: -5px;
    }
    .caption-tool-menu .element-tools .element {
        height: 60px;
        background-size: 55% !important;
        max-width: 100px !important;
    }
    .caption-tool-menu .element-tools{
        min-width: 100%;
    }
    .caption-tool-menu .navbar-nav .nav-link {
        padding: 7px !important;
    }
    .caption-tool-menu .navbar-nav .nav-item{
        padding: 0px !important;
    }
    .preview-tools {
        position: absolute;
        top: initial;
        right: 14px;
        z-index: 20;
        white-space: nowrap;
    }
}
@media (max-width:767px){
    header .search-bar {
        width: 310px;
        margin: auto;
    }
    .caption-tool-menu .navbar-nav .nav-link {
        padding: 7px !important;
        font-size: 13px;
    }
    .hide-mobile{
        display: none;
    }
    .caption-editor .element {
        min-width: 150px;
        min-height: 142px;
        width: 260px;
        height: 186px;
    }
    /* .textarea-box{
        top: 20%;
    } */
    .hide-listing {
        transform: translateX(3000px);
        transition: 500ms;
    }
    .container-preview {
        margin-top: -20px;
        padding-right: 15px;
    }
    .share-listing {
        position: fixed;
        width: 48px;
        top: initial;
        left: 0;
        bottom: 0px;
        display: flex;
        width: 100%;
        flex-direction: row;
        margin: auto;
        justify-content: center;
        box-shadow: 0px 0px 4px 2px #eee;
        background: #fff;
        z-index: 11;
    }
    .previews-btn {
        position: absolute;
        width: 100%;
        right: 0;
        margin-top: -43px;
        left: -20px;
        z-index: 999;
    }
    .previews-btn button{
        display: block;
    }
    .hide-listing.active {
        transform: translateX(10px);
    }
    .caption-watermark{
        width: 72px;
    }
}
@media (max-width:480px){
    header .search-bar {
        width: 210px;
        margin: auto;
    }
}


.tiny-preview{
    width: 100px;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
}
@media (max-width:768px){
    .tiny-preview{ 
        min-height: 200px;
        max-height: calc(100vh - 180px);
    }
}
.image-tinypreview{
    position: relative;
    height: 90px;
    border-bottom: 2px solid #000;
    overflow: hidden;
}
.image-tinypreview img{
    width: 100%;
    /* max-width: 100%; */
    height: 100%;
    /* max-height: 100%; */
    /* object-fit: cover; */
    /* object-position: center; */
    display: block;
    margin: 0 auto;
}
.tiny-tools{
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 2;
    width: 24px;
}
.tiny-tools button{
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
    margin-bottom: 6px;
    font-size: 18px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #dc3545;
    box-shadow: 0 0 6px #999;
}
.tiny-tools button:hover{
    background-color: #dc3545;
    color: #fff;
}
[data-countimg="1"] .image-tinypreview:nth-child(1) .tiny-tools button:nth-child(1){
    display: none;
}


[data-turn="90"] img{
    transform-origin: 0 100%;
    transform: translateY(-100%) rotate(90deg);
}

[data-turn="180"] img{
    transform-origin: 0 50%;
    transform: translateX(100%) rotate(180deg);
}
[data-turn="270"] img{
    transform-origin: 100% 0;
    transform: translateX(-100%) rotate(-90deg);
}

.image-bigpreview{
    overflow: hidden;
    margin-bottom: 25px;
}
.image-bigpreview img{
    /* max-width: 100%; */
}
.caption-editor .image-bigpreview:last-child{
    margin-bottom: 0!important;
}




.add-anotherimg{
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 2;
    overflow: hidden;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.add-anotherimg input{
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
}
.add-anotherimg img{
    width: 30px;
}
.add-anotherimg span{
    font-size: 13px;
    display: block;
    padding-top: 6px;
    opacity: 0.75;
}
/* .currentlyDragged{
    position: fixed!important;
} */
